import { AutoComplete } from 'antd'
import { useEffect, useState } from 'react'
import countryList from '../../constants/countryList.json'
import { useTranslation } from 'react-i18next'

interface AutocompletePaysProps {
  label: string
  value: string
}

type AutocompleteProps = {
  /**
   * Update value when select 1 option
   **/
  cbUpdateForm?: ({
    name,
    selectedValue,
  }: {
    name: string
    selectedValue: string
  }) => void
  /**
   * Update value when onchange
   * */
  onChangeField?: (text: string) => void
  /**
   * max digit can be inputted
   */
  maxLength?: number
  /**
   * classname of the autocomplete
   */
  className?: string
  /**
   * name of autocomplete when put into form
   */
  name?: string
  /**
   * init data
   */
  initValue?: string
  /**
   * disable search
   */
  disable?: boolean
  id?: string
  nextId?: string
  previousId?: string
}

/**
 * The @AutocompletePays is an autocomplete to find pays. When users enter characters it will show maximum 6 items.
 * When user out focus @AutocompletePays , if the content inside @AutocompletePays not match any items of
 * countryList, @AutocompletePays will auto clear value.
 * @author [anhnq]
 * @version 0.1
 */
const AutocompletePays = (props: AutocompleteProps) => {
  const {
    cbUpdateForm = () => {},
    maxLength = Number.POSITIVE_INFINITY,
    onChangeField = () => {},
    className = '',
    name = '',
    initValue = '',
    disable = false,
    id,
    nextId,
    previousId,
  } = props
  const [query, setQuery] = useState<string>('')
  const [value, setValue] = useState<string>('')
  const { t } = useTranslation()

  useEffect(() => {
    if (!initValue) return
    setValue(initValue)
  }, [initValue])

  const filter = (array: AutocompletePaysProps[], searchQuery: string) => {
    var filterPattern = searchQuery.trim()
    if (!filterPattern) return []
    if (!filterPattern[0].match(/[a-zA-Z]/i)) return
    if (filterPattern.includes('(') || filterPattern.includes(')')) {
      filterPattern = filterPattern.split('(')[0].trim()
    }
    const filterRegEx = new RegExp(filterPattern, 'i')
    return array
      .filter((item: AutocompletePaysProps) => filterRegEx.test(item.value))
      .filter((_: AutocompletePaysProps, idx: number) => idx < 6)
  }

  const onClickOutsideAutoComplete = () => {
    if (value) {
      const findElement = countryList.filter((el) => el.value === value)
      if (!findElement.length) {
        setValue('')
      }
    }
  }

  return (
    <div>
      <AutoComplete
        aria-roledescription={nextId}
        aria-keyshortcuts={previousId}
        tabIndex={0}
        id={id}
        data-next-id={nextId}
        notFoundContent={
          <p style={{ fontSize: 12, margin: '5px 0' }}>{t('notFoundResult')}</p>
        }
        placement="bottomRight"
        allowClear
        filterOption
        maxLength={maxLength}
        value={value}
        options={filter(countryList, query)}
        onSearch={(string) => setQuery(string)}
        onSelect={(data) => {
          cbUpdateForm({ name, selectedValue: data })
        }}
        onBlur={() => onClickOutsideAutoComplete()}
        onChange={(string) => {
          onChangeField(string)
          setValue(string)
        }}
        className={className}
        disabled={disable}
      />
    </div>
  )
}

export default AutocompletePays
